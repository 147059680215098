import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>El Llibre</Title>

                <Text>
                    Ja disponible la segona edició!
                    <br />
                    <br />
                    El llibre de casos pràctics va néixer de la necessitat de conèixer de fons que
                    és un cas pràctic i com afrontar-lo.
                    <br />
                    <br />
                    Amb el llibre us endinsareu de fons a un món policial on aprendreu des de
                    consells generals, passos i resolucions, tipus d'actes, minutes, compareixences
                    i tres grans temàtiques amb casos pràctics.
                    <br />
                    <br />
                    Les temàtiques més importants són: Seguretat ciutadana, trànsit i policia
                    administrativa.
                    <br />
                    <br />
                    Tots els casos pràctics s'han resolt d'acord amb la normativa jurídica vigent i
                    s'ha annexat el text normatiu, amb especial menció d'on queden regulats els fets
                    de cada cas pràctic.
                    <br />
                    <br />
                    Un llibre únic, pensat tant per a opositors que encara no han tingut cap mena de
                    contacte amb el món policial, com alumnes de l'ISPC, policies que volen
                    promocionar o lectors que vulguin veure com es poden desenvolupar les actuacions
                    policials més comunes.
                    <br />
                    <br />
                    Al llibre trobaràs moltíssims casos pràctics, des dels més complexos fins a
                    altres més simples, tots ells han estat extrets d'exàmens oficials per agents de
                    policia, per places interadministratives, d'ascens, etc.
                    <br />
                    <br />
                    Un llibre essencial.
                    <br />
                    <br />
                    Els enviaments es faran a partir del 30 de novembre de 2022
                </Text>

                {/** Preview del libro */}
                <div className="container lg:px-0 py-20">
                    <Title>Preview del llibre</Title>
                    <Text className="text-justify">
                        A continuació us adjuntem un resum en format pdf del contingut del curs.
                    </Text>

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/145/preview.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        ></embed>
                    </div>
                </div>

                <div className="container lg:px-0 py-20">
                    <Title>Preview del llibre</Title>
                    <Text className="text-justify">
                        A continuació us adjuntem un resum en format pdf del contingut del curs.
                    </Text>

                    <p className="mt-5">
                        <a
                            rel="noopener noreferrer"
                            href={require("@static/documents/products/145/preview.pdf")}
                            target="_blank"
                            className="default-link"
                        >
                            Clic per descarregar el pdf
                        </a>
                        .
                    </p>

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/246/preview.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        />
                    </div>
                </div>
            </div>

            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20 ">
                    <Title>¿Cómo puedo adquirirlo?</Title>
                    <Text className="text-justify">
                        Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                        <Link to="/tienda" className="default-link">
                            Tienda
                        </Link>{" "}
                        haciendo clic en el <strong>botón de añadir a la cesta</strong>.
                        <br />
                        <br />
                        Una vez recibamos la orden de compra procederemos al envío del libro. El
                        envío se realiza a través de la empresa GLS en la modalidad de envío
                        certificado. La empresa GLS os enviará un mail para que podáis saber en todo
                        momento donde se encuentra vuestro libro y si es necesario os llamarán por
                        teléfono para contactar con vosotros. El tiempo en ser entregado es
                        aproximadamente 48 horas.
                        <div className="text-left text-xs font-montserrat italic mt-10">
                            * Nos comprometemos en realizar el envío el día siguiente de realizar el
                            pago aunque no podemos hacernos responsables de los posibles retrasos
                            que la empresa transportista pueda ocasionar.
                        </div>
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
